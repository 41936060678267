export function contentLinksInit(){
  $(".content a").each(function(){
    //add file type,size and GA to documents/files
    //converts href="/link-to-assest.pdf||data-type=pdf@data-size=1.7 MB@class=file@" to link text, file zise and extension for links to files
    var link = $(this).attr('href');
    if (link != null) {
      if (link.indexOf('@data-size') >= 0) {
        var hrefParts = link.split('||');
        var hrefAttrs = hrefParts[1].split('@');
        var docType = hrefAttrs[0].split('=');
        var docSize = hrefAttrs[1].split('=');
        var linkText = $(this).text() + ' (' +  docType[1].toUpperCase() + ' ' +  docSize[1] + ')';
        $(this).text(linkText);
        $(this).attr('href',hrefParts[0]);
        $(this).addClass("js-ga-file-link");
      }
    }
  });
}

export function contentTableWrapper(){
  $(".content table").wrap("<div class='content__table-overflow-wrapper'></div>");
}
