const closeQuickFormJS = $('.js-close-quick-form');
const errorMessage = $('.error-message');
const form = $('.js-quick-feedback-form');
const formElement = $('.js-quick-feedback-form')[0];
const quickFeedbackAnswer = form.find('.js-quick-feedback-answer');
const quickFeedbackFullFeedbackButton = $('.js-quick-feedback-full-feedback-button');
const quickFeedbackFullFeedbackLabel = $('.js-quick-feedback-full-feedback-label');
const quickFeedbackHelperText = $('.js-quick-feedback-helper-text');
const quickFeedbackSubmit = form.find('.js-quick-feedback-submit');
const quickFeedbackTsAndCs = form.find('.js-quick-feedback-terms-and-conditions');
const submissionHashInput = form.find('input[name=SubmissionHash]');
const submittedMessage = $('.js-submitted-message');


/**
 * Initializes the quick form by attaching event listeners and setting up form elements.
*/
export function initialiseQuickForm() {
  const contentFeedBackWrapper = $('.tab-content__feedback-wrapper');
  const formHelper = $('.form__helper--quick-feedback');
  const radioInputs = form.find('input[type=radio]');
  
  /**
   * Handles reCAPTCHA execution and form submission.
  */
 const handleRecaptchaAndSubmit = () => {
    const inputID = 'Nocaptcha-' + formElement.getAttribute('id');
    
    if (!inputID) {
      console.error('No input ID found');
      return;
    }
    
    executeRecaptcha(inputID)
      .then(() => {
        window.submitQuickForm();
      })
      .catch((error) => {
        console.error('Error executing reCAPTCHA:', error);
      });
  };

  let prevFocus;

  if (form.length) {
    loadSubmissionFromCookie();

    // Attach change event listener to radio inputs
    radioInputs.on('change', function () {
      prevFocus = $(this);
      handleRecaptchaAndSubmit();
    });

    // Attach submit event listener to form
    form.on('submit', function (e) {
      e.preventDefault();
      if (window.validateQuickForm()) {
        handleRecaptchaAndSubmit();
      }
    });
  }

  // Attach keyup event listener to quick feedback answer input
  quickFeedbackAnswer.on('keyup', function () {
    quickFeedbackFullFeedbackButton.addClass('u-hidden');
    quickFeedbackFullFeedbackLabel.addClass('u-hidden');
    quickFeedbackSubmit.removeClass('u-hidden');
    quickFeedbackTsAndCs.removeClass('u-hidden');
  });


  // Attach focus event listener to text inputs and textareas
  $(document).on('focus', 'input[type=text], input[type=search], textarea', function () {
    if (quickFeedbackAnswer.is(':focus')) {
      contentFeedBackWrapper.removeClass('text-input-has-focus');
      formHelper.addClass('answer-has-focus');
    } else {
      contentFeedBackWrapper.addClass('text-input-has-focus');
      formHelper.removeClass('answer-has-focus');
    }
  });

  // Attach blur event listener to text inputs and textareas
  $(document).on('blur', 'input[type=text], input[type=search], textarea', function () {
    contentFeedBackWrapper.removeClass('text-input-has-focus');
    formHelper.removeClass('answer-has-focus');
  });

  /**
   * Validates the quick form before submission.
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  window.validateQuickForm = function () {
    form.find('.quick-feedback__error-message').remove();
    errorMessage.remove();

    if (!quickFeedbackAnswer.first().hasClass('u-hidden')) {
      if (quickFeedbackAnswer.find('textarea').val() === '') {
        quickFeedbackSubmit.before($('<div class="quick-feedback__error-message">Please provide some feedback before submitting.</div>'));
        return false;
      } else if (!$('.js-quick-feedback-terms-and-conditions').find('input').prop('checked')) {
        quickFeedbackSubmit.before($('<div class="quick-feedback__error-message">Please accept the terms of use before submitting.</div>'));
        return false;
      }
      return true;
    }
  };

  /**
   * Submits the quick form via AJAX.
   */
  window.submitQuickForm = function () {

    /**
     * Updates Google Analytics tag and focuses on the previous input element.
     * @param {string} eventName - The name of the event.
     */
    const updateGtagAndFocus = (eventName) => {
      gtag('event', eventName, {
        event_category: 'Form - quick form',
        event_label: window.location.pathname
      });

      if (prevFocus) {
        prevFocus.focus();
        prevFocus = null;
      }
    };

    /**
     * Updates the submission hash and saves it to a cookie.
     * @param {string} submissionHash - The submission hash value.
     */
    const updateSubmissionHashAndSaveToCookie = (submissionHash) => {
      submissionHashInput.val(submissionHash);
      saveSubmissionToCookie(submissionHash);
    };

    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: form.serialize(),
      success: function (result) {
        submittedMessage.addClass('u-hidden');

        if (result && typeof result === 'object') {
          if (result.error && result.field && result.message) {
            const field = form.find('[name=' + result.field + ']');
            if (field.length > 0) {
              quickFeedbackSubmit.before($('<div class="quick-feedback__error-message">' + result.message + '</div>'));
            } else {
              console.error('Invalid field:', result.field);
            }
          } else if (result.quickFormStage === 'secondary' && result.submissionHash) {
            updateGtagAndFocus('Success - sentiment');
            quickFeedbackAnswer.removeClass('u-hidden');
            quickFeedbackHelperText.removeClass('u-hidden');
            quickFeedbackFullFeedbackLabel.removeClass('u-hidden');
            closeQuickFormJS.removeClass('u-hidden');
            updateSubmissionHashAndSaveToCookie(result.submissionHash);
          } else if (result.quickFormStage === 'finished' && result.submissionHash) {
            updateGtagAndFocus('Success - feedback answer');
            closeQuickForm();
            quickFeedbackFullFeedbackButton.addClass('u-hidden');
            submittedMessage.removeClass('u-hidden');
            updateSubmissionHashAndSaveToCookie(result.submissionHash);
          }
        } else {
          console.error('Invalid JSON response:', result);
        }
      }
    });
  };
}


function executeRecaptcha(inputID) {
  return new Promise((resolve, reject) => {
    try {
      let input = document.getElementById(inputID);
      grecaptcha.execute(input.getAttribute('data-sitekey'), { action: 'submit' })
        .then(function (token) {
          input.value = token;
          resolve();
        });
    } catch (e) {
      if (e.name == 'ReferenceError') {
        resolve();
      } else {
        reject(e);
      }
    }
  });
}

/**
 * Closes the quick form by resetting the form fields and hiding certain elements.
 */
export function closeQuickForm() {
  closeQuickFormJS.addClass('u-hidden');
  form.find('.error-message').remove();
  form.find('.quick-feedback__error-message').remove();
  quickFeedbackAnswer.addClass('u-hidden');
  quickFeedbackAnswer.val('');
  quickFeedbackFullFeedbackButton.removeClass('u-hidden');
  quickFeedbackFullFeedbackLabel.addClass('u-hidden');
  quickFeedbackHelperText.addClass('u-hidden');
  quickFeedbackSubmit.addClass('u-hidden');
  quickFeedbackTsAndCs.addClass('u-hidden');
  quickFeedbackTsAndCs.prop('checked', false);
}

/**
 * Loads the submission data from the cookie and populates the form with the saved values.
 */
function loadSubmissionFromCookie() {

  const formID = $.grep(formElement.className.split(" "), function (v, i) {
    return v.indexOf('js-quick-feedback-form-id-') === 0;
  }).join().replace('js-quick-feedback-form-id-', '');
  const submissionHash = getCookie("feedbackSubmissionHash" + formID);
  const selectedSentiment = getCookie("feedbackSelectedSentiment" + formID);

  if (submissionHash.length > 0 && selectedSentiment.length > 0) {
    form.find('#Form_QuickFeedbackForm_Sentiment_' + selectedSentiment).prop('checked', true);
    submissionHashInput.val(submissionHash);
  }
}


/**
 * Saves the submission hash and selected sentiment to a cookie.
 * @param {string} submissionHash - The submission hash to be saved.
 */
function saveSubmissionToCookie(submissionHash) {
  const formID = $.grep(formElement.className.split(" "), function (v, i) {
    return v.indexOf('js-quick-feedback-form-id-') === 0;
  }).join().replace('js-quick-feedback-form-id-', '');

  let cookieExpiryDate = new Date();
  
  cookieExpiryDate.setMonth(cookieExpiryDate.getMonth() + 2);
  document.cookie = "feedbackSelectedSentiment" + formID + "=" + form.find('input[name=Sentiment]:checked').val() + "; expires=" + cookieExpiryDate.toUTCString();
  document.cookie = "feedbackSubmissionHash" + formID + "=" + submissionHash + "; expires=" + cookieExpiryDate.toUTCString();
}

/**
 * Retrieves the value of a cookie by its name.
 * @param {string} cname - The name of the cookie.
 * @returns {string} The value of the cookie, or an empty string if the cookie is not found.
 */
function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}