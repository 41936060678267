let offset = 0;
export function loadMoreFeedback() {
		offset+=20;
		$('.js-load-more-feedback-spinner').removeClass('u-hidden');
		$.ajax({
    	url: window.location.pathname + '/loadMorePublicFeedback',
    	data:{'offset': offset}
	  })
	  .done(function(res) {
			$('.js-load-more-feedback-spinner').addClass('u-hidden');
	  	if(res){
				$('.js-load-more-feedback-wrapper').append(res);
				window.sya.updateStickyElements();
	  	}else{
	  		$('.js-load-more-feedback-wrapper').append("<p align='center'>You've reached the end of the public feedback.</p>");
	  		$('.js-load-more-feedback-button').addClass('u-hidden');
	  	}
	  })
	  .fail(function(err) {
	  	$('.js-load-more-feedback-spinner').addClass('u-hidden');
	  	$('.js-load-more-feedback-button').addClass('u-hidden');
	  });
		    
}