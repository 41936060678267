/*global gtag*/
import {feeCodeMap} from './motor-vehicle-class-mapping.js'
import {fuelTypeMap} from './motor-vehicle-fuel-type-mapping.js'

export function validatePlate(){
  // INSTANTIATE ERROR MESSAGE VARIABLES
  //instantiate and reset empty input error message
  let requiredErrorMessage = document.getElementsByClassName("motor-levy__form-required-error-message");
  requiredErrorMessage[0].classList.add("u-hidden");

  //instantiate and reset error message
  let errorMessage = document.getElementsByClassName("motor-levy__form-error-message");
  errorMessage[0].classList.add("u-hidden");

  //instantiate and reset reg not found message
  let regNotFoundMessage = document.getElementsByClassName("motor-levy__form-reg-not-found-message");
  regNotFoundMessage[0].classList.add("u-hidden");


  let plate = document.getElementById("motor-levy-input").value;

  if (plate === "") {
    let requiredErrorMessage = document.getElementsByClassName("motor-levy__form-required-error-message");
    requiredErrorMessage[0].classList.remove("u-hidden");
    return;
    } else {
      searchNumberPlate(plate);
    }
}


export function searchNumberPlate(plate){

  $.ajax({
    url: MVL.PlateSearchApiUrl+plate+"%22%7d"
  })
  .done(function( data ) {
    //remove callback( function start
    data = data.substr(9);
    //remove last ) from callback
    data = data.substr(0, data.length-1);
    if(data != ""){
      try {
        const jsonData = JSON.parse(data);

        let vehicleClass = lookupVehicleClass(jsonData.ResponseDetail.FeeCode);

        calculateMotorVehicleLevy(vehicleClass, jsonData.ResponseDetail);


      } catch(e) {
        console.log(e);
        let regNotFoundMessage = document.getElementsByClassName("motor-levy__form-reg-not-found-message");
        regNotFoundMessage[0].classList.remove("u-hidden");
      }
    }else{
      //no results
      let regNotFoundMessage = document.getElementsByClassName("motor-levy__form-reg-not-found-message");
      regNotFoundMessage[0].classList.remove("u-hidden");
    return;
    }
  })
  .fail(function(err) {
    console.log('Error: ' + err.status);
  });
}


function lookupVehicleClass(feeCode){
  return feeCodeMap[feeCode] ? feeCodeMap[feeCode] : "Not found";
}

function lookupVehicleFuelType(fuelType){
  return fuelTypeMap[fuelType] ? fuelTypeMap[fuelType] : "Not found";
}


function calculateMotorVehicleLevy(vehicleClass,vehicleInfo){
  //lookup to see if in the cms data list
  if(MVL.ClassData[vehicleClass]){


    //Uili:  confirm this works with all scenarios - talk to rhys about when we will get the numberplates
    if(vehicleInfo.FeeBreakdown[1].Value){

      let proposed = parseFloat(MVL.ClassData[vehicleClass]);
      let current = parseFloat(vehicleInfo.FeeBreakdown[1].Value);
      let difference = proposed - current;

      let vehicleAtt = vehicleInfo.VehicleAttributes;
      let plateNumber = document.getElementsByClassName("motor-levy-input")[0].value;
      let makeName = vehicleAtt.MakeName;
      let modelName = vehicleAtt.ModelName;
      let Seats = vehicleAtt.Seats;
      let ccRating = vehicleAtt.CcRating;
      let fuelType = lookupVehicleFuelType(vehicleAtt.FuelType);
      let vehicleYear = vehicleAtt.VehicleYear
      let NZCurrency = {style: 'currency', currency: 'NZD'};

      if(difference == 0){
        document.getElementById("mvl-calculated-difference-text").innerHTML = "No difference";
      } else if(difference < 0){
        document.getElementById("mvl-calculated-difference-text").innerHTML = "Decrease";
      } else {
        document.getElementById("mvl-calculated-difference-text").innerHTML = "Increase";
      }

      document.getElementById("MotorLevyCurrent").innerHTML = (current).toLocaleString('en-NZ', NZCurrency);
      document.getElementById("MototLevyProposed").innerHTML = (proposed).toLocaleString('en-NZ', NZCurrency);
      document.getElementById("motorLevyDifference").innerHTML = Math.abs(difference).toLocaleString('en-NZ', NZCurrency);

      document.getElementById("js-motor-levy__plate-result").innerHTML = "Plate " + plateNumber;
      document.getElementById("js-motor-levy__make-model").innerHTML = makeName + " " + modelName;
      document.getElementById("js-motor-levy__vehicle-attributes").innerHTML = Seats + " Seater " + ccRating + "cc, " + fuelType + " (" + vehicleYear + ")";


      //hide input step
      var inputElement = document.getElementsByClassName("motor-levy__input-wrapper");
      inputElement[0].classList.add("u-hidden");

      //reveal result step
      var resultElement = document.getElementsByClassName("motor-levy__calculated-wrapper");
      resultElement[0].classList.remove("u-hidden");
      window.sya.updateStickyElements();
      //Fire off GA event
  		gtag('event', 'Submit - estimate', {
  		  event_category: "Tool - motor vehicle levy estimator",
  		  event_label: window.location.pathname
  		});

    }else{
      // no current vehicle info found error message
      let regNotFoundMessage = document.getElementsByClassName("motor-levy__form-reg-not-found-message");
      regNotFoundMessage[0].classList.remove("u-hidden");
    }


  }else{
    let errorMessage = document.getElementsByClassName("motor-levy__form-error-message");
    errorMessage[0].classList.remove("u-hidden");
  }
}

export function startOverCalculationMotorLevy() {

  document.getElementById("MotorLevyCurrent").innerHTML = "$0.00";
  document.getElementById("MototLevyProposed").innerHTML = "$0.00";
  document.getElementById("motorLevyDifference").innerHTML = "$0.00";

  document.getElementById("motor-levy-input").value = "";

  //Hide results div
  var resultElement = document.getElementsByClassName("motor-levy__calculated-wrapper");
  resultElement[0].classList.add("u-hidden");

  //show reveal input input element
  var inputElement = document.getElementsByClassName("motor-levy__input-wrapper");
  inputElement[0].classList.remove("u-hidden");
}

export function initialiseMVLKeypressEnter(){
  var input = document.getElementById("motor-levy-input");
  if(input){
    input.addEventListener("keyup", function(event) {
      // Cancel the default action, may be a bug, sometimes doesn't prevent default
      event.preventDefault();
      event.stopPropagation();
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Trigger the button element with a click
        document.getElementById("motory-vehicle-plate-submit-button").click();
      }
    });
  }
}