export function accordionToggle(accordionID) {
	const accordion = document.getElementById(accordionID)

	if (accordion) {
		const accordionContent = accordion.querySelector(".js-accordion-content")
		const accordionButton = accordion.querySelector(".js-accordion-button")
		const accordionExpand = accordionButton.querySelector(".js-accordion-button-expand")
		const accordionCollapse = accordionButton.querySelector(".js-accordion-button-collapse")
		if (accordionContent && accordionButton && accordionExpand && accordionCollapse) {
			if (accordionButton.getAttribute("aria-expanded") === "false") { // Expand accordion
				accordionButton.setAttribute("aria-expanded", "true")
				accordionContent.classList.add("accordion__content--show")
				accordionContent.setAttribute("aria-hidden", "false")
				accordionExpand.classList.remove("accordion__button-expand--show")
				accordionExpand.setAttribute("aria-hidden", "true")
				accordionCollapse.classList.add("accordion__button-collapse--show")
				accordionCollapse.setAttribute("aria-hidden", "false")
				window.sya.updateStickyElements();
			} else if (accordionButton.getAttribute("aria-expanded") === "true") { // Collapse accordion
				accordionButton.setAttribute("aria-expanded", "false")
				accordionContent.classList.remove("accordion__content--show")
				accordionContent.setAttribute("aria-hidden", "true")
				accordionExpand.classList.add("accordion__button-expand--show")
				accordionExpand.setAttribute("aria-hidden", "false")
				accordionCollapse.classList.remove("accordion__button-collapse--show")
				accordionCollapse.setAttribute("aria-hidden", "true")
			}
		}
	}
}
