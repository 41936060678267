import shave from 'shave'

export function calcFooterOffset(){
  let offset = 0;
  //laptop size
  if ($(window).width() <= 992) {
    //if on topic page use topic feedback height
    if($('.js-feedback-topic-height').length){
      offset = $('.js-feedback-topic-height').outerHeight();
    } else { 
      offset = $('.js-feedback-header-height').outerHeight();
    }
  }
  $('.footer').css('margin-bottom', offset );
}

export function truncateTopicCardContent(){
  if($('.js-shave-topic').length){
    //need to loop
    $( ".js-shave-topic" ).each(function() {
      let titleHeight = $( this ).find('.js-shave-topic-title').outerHeight(true);
      let wrapperHeight = $( this ).outerHeight();
      let maxcontentHeight = wrapperHeight - titleHeight;
      shave($( this ).find('.js-shave-topic-content'), maxcontentHeight);
    });
    
  }
}

export function truncateDiscussionCardContent(){
  
  if($('.js-shave-discussion-card-wrapper').length){
    $('.js-shave-discussion-card-wrapper').each(function() {
      let contentHeight = $( this ).find('.js-shave-discussion-content').outerHeight();
      let titleHeight = 45; //accounts for two lines
      let wrapperHeight = $( this ).outerHeight();
      let buttonHeight = $(this).find('.js-shave-discussion-button').outerHeight();
      let maxcontentHeight = wrapperHeight - (contentHeight + titleHeight + buttonHeight);
      shave( $( this ).find('.js-shave-discussion-topic-info') , maxcontentHeight);
    });
  }
}