export function filter(filter) {
  filterLandingHide()

  // Hide/Show topic cards depending on selected filter
  const items = document.querySelectorAll('.js-topic-card');
  for (let i = 0; i < items.length; i++) {
    if (!filter) {
      items[i].classList.remove('topic-card--hide');
    } else {
      if (items[i].dataset.tags.indexOf(filter) > -1) {
        items[i].classList.remove('topic-card--hide');
      } else {
        items[i].classList.add('topic-card--hide');
      }
    }
  }

  // Highlight selected filter
  const filters = document.querySelectorAll('.js-filter-item');
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].dataset.tag === "" && !filter) {
      filters[i].classList.add('filter__item--selected');
    } else if (filters[i].dataset.tag === filter) {
      filters[i].classList.add('filter__item--selected');
    } else {
      filters[i].classList.remove('filter__item--selected');
    }
  }

  // Update title of filter button to selected filter (mobile)
  const filterButtonTitle = document.querySelector('.js-filter-button-title');
  if (!filter) {
    filterButtonTitle.innerHTML = "All Topics";
  } else {
    filterButtonTitle.innerHTML = filter;
  }
}

export function toggleFilterOverlay() {
  // Hide/Show overlay of filter selection (mobile)
  const filterOverlay = document.querySelector('.js-filter-overlay');
  if (filterOverlay.classList.contains('filter__overlay--show')) {
    filterOverlay.classList.remove('filter__overlay--show');
    $('body').removeClass('u-noscroll--until-laptop');
  } else {
    filterOverlay.classList.add('filter__overlay--show');
    $('body').addClass('u-noscroll--until-laptop');
  }
}

export function filterLandingShow() {
  // Show landing page & hide other sections
  const landing = document.querySelector('.js-discussion-landing');
  const sections = document.querySelector('.js-discussion-sections');
  if (landing) {
    landing.classList.add('discussion-landing--show');
  }
  if (sections) {
    sections.classList.remove('discussion-sections--show');
  }
}

export function filterLandingHide() {
  // Hide landing page & display other sections
  const landing = document.querySelector('.js-discussion-landing');
  const sections = document.querySelector('.js-discussion-sections');
  if (landing) {
    landing.classList.remove('discussion-landing--show');
  }
  if (sections) {
    sections.classList.add('discussion-sections--show');
  }
}

// Check if filter landing has been seen
export function checkFilterLandingVisiability() {
  // Check if landing is in DOM
  const landing = document.querySelector('.js-discussion-landing');
  const sections = document.querySelector('.js-discussion-sections');
  if (landing) {
    if (document.cookie.split(';').filter((item) => item.indexOf('filterLandingSeen=true') >= 0).length) {
      // Filter landing has been seen, hide it
      filterLandingHide()
    } else {
      // Set filter landing as seen
      filterLandingShow()
      document.cookie = "filterLandingSeen=true"

    }
  } else {
    if (sections) {
      sections.classList.add('discussion-sections--show');
    }
  }
}
