//make this editable at some point
export const fuelTypeMap = {
  1: "Petrol",
  2: "Deisel",
  3: "CNG",
  4: "LPG",
  5: "Electric",
  6: "Other",
  7: "Petrol Hybrid",
  8: "Diesel Hybrid",
  9: "Petrol Electric Hybrid",
  10: "Diesel Electric Hybrid",
  11: "Plug-in Petrol Hybrid",
  12: "Plug-in Diesel Hybrid",
  13: "Electric [Petrol Extended]",
  14: "Electric [Diesel Extended]",
  15: "Electric Hydrogen Fuel Cell",
  16: "Electric Other Fuel Cell",
};