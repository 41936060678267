//make this editable at some point
export const feeCodeMap = {
1001:"4A",
1002:"3",
1003:"3",
1021:"4A",
1022:"3",
1023:"3",
1041:"4A",
1042:"3",
1043:"3",
1061:"3",
1062:"3",
1063:"3",
1081:"3",
1082:"3",
1083:"3",
1101:"4A",
1121:"4A",
1122:"3",
1123:"3",
1141:"Exempt",
1142:"Exempt",
1143:"Exempt",
1161:"4A",
1162:"3",
1163:"3",
1402:"Exempt",
1403:"Exempt",
1404:"Exempt",
1405:"Exempt",
1406:"Exempt",
1421:"Exempt",
1422:"Exempt",
1423:"Exempt",
1424:"Exempt",
1425:"Exempt",
1426:"Exempt",
1427:"Exempt",
1428:"Exempt",
1429:"Exempt",
1441:"Exempt",
1442:"Exempt",
1443:"Exempt",
1444:"Exempt",
1445:"Exempt",
1446:"Exempt",
1447:"Exempt",
1448:"Exempt",
1449:"Exempt",
1450:"Exempt",
1451:"Exempt",
1452:"Exempt",
1461:"Exempt",
1462:"Exempt",
1463:"Exempt",
1464:"Exempt",
1465:"Exempt",
1466:"Exempt",
1467:"Exempt",
1468:"Exempt",
1469:"Exempt",
1481:"Exempt",
1482:"Exempt",
1483:"Exempt",
1501:"Exempt",
1502:"Exempt",
1503:"Exempt",
1504:"Exempt",
1505:"Exempt",
1506:"Exempt",
1507:"Exempt",
1508:"Exempt",
1509:"Exempt",
1521:"Exempt",
1522:"Exempt",
1523:"Exempt",
1541:"Exempt",
1542:"Exempt",
1543:"Exempt",
1544:"Exempt",
1545:"Exempt",
1546:"Exempt",
1561:"Exempt",
1562:"Exempt",
1563:"Exempt",
1564:"Exempt",
1565:"Exempt",
1566:"Exempt",
1567:"Exempt",
1568:"Exempt",
1569:"Exempt",
1570:"Exempt",
1571:"Exempt",
1572:"Exempt",
1573:"Exempt",
1574:"Exempt",
1575:"Exempt",
1801:"3",
1802:"3",
1803:"3",
1821:"3",
1822:"3",
1823:"3",
1824:"Exempt",
1825:"Exempt",
1826:"Exempt",
1827:"Exempt",
1828:"Exempt",
1829:"Exempt",
1830:"3",
1831:"3",
1832:"3",
1833:"3",
1834:"3",
1835:"3",
1841:"Exempt",
1842:"Exempt",
1843:"Exempt",
2001:"3",
2002:"3",
2003:"3",
2021:"Exempt",
2022:"Exempt",
2023:"Exempt",
2024:"Exempt",
2025:"Exempt",
2026:"Exempt",
2027:"Exempt",
2028:"Exempt",
2029:"Exempt",
2030:"3",
2031:"3",
2032:"3",
2033:"3",
2034:"3",
2035:"3",
2036:"Exempt",
2037:"Exempt",
2038:"Exempt",
2039:"Exempt",
2040:"Exempt",
2041:"Exempt",
2042:"2",
2043:"3",
2044:"3",
2045:"2",
2046:"3",
2047:"3",
2201:"Exempt",
2202:"Exempt",
2203:"Exempt",
2221:"Exempt",
2222:"Exempt",
2223:"Exempt",
2241:"Exempt",
2242:"Exempt",
2243:"Exempt",
2401:"2",
2402:"3",
2403:"3",
2421:"Exempt",
2422:"Exempt",
2423:"Exempt",
2441:"Exempt",
2442:"Exempt",
2443:"Exempt",
2601:"Exempt",
2602:"Exempt",
2603:"Exempt",
2604:"Exempt",
2605:"Exempt",
2621:"Exempt",
2622:"Exempt",
2623:"Exempt",
2624:"Exempt",
2625:"Exempt",
2626:"Exempt",
2641:"Exempt",
2642:"Exempt",
2643:"Exempt",
2644:"Exempt",
2645:"Exempt",
2646:"Exempt",
2661:"2",
2662:"3",
2663:"3",
2664:"2",
2665:"2",
2666:"2",
2681:"2",
2682:"3",
2683:"3",
2684:"2",
2685:"2",
2686:"2",
2687:"2",
2688:"2",
2689:"2",
2690:"2",
2691:"2",
2701:"2",
2702:"3",
2703:"3",
2704:"2",
2705:"2",
2706:"2",
2707:"2",
2708:"2",
2709:"2",
2710:"2",
2721:"2",
2722:"3",
2723:"3",
2724:"2",
2725:"2",
2726:"2",
2741:"3",
2742:"3",
2743:"3",
2761:"3",
2762:"3",
2763:"3",
2781:"2",
2782:"2",
2783:"2",
2784:"2",
2785:"2",
2786:"2",
2787:"2",
2788:"2",
2789:"2",
2790:"2",
2801:"3",
2802:"3",
2803:"3",
2821:"Exempt",
2822:"Exempt",
2823:"Exempt",
2824:"Exempt",
2825:"Exempt",
2826:"Exempt",
2841:"2",
2842:"3",
2843:"3",
2844:"2",
2845:"2",
2846:"2",
3001:"Exempt",
3002:"Exempt",
3003:"Exempt",
3004:"Exempt",
3005:"Exempt",
3006:"Exempt",
3007:"Exempt",
3008:"Exempt",
3009:"Exempt",
3010:"Exempt",
3011:"Exempt",
3012:"Exempt",
3021:"Exempt",
3022:"Exempt",
3023:"Exempt",
3024:"Exempt",
3025:"Exempt",
3026:"Exempt",
3027:"Exempt",
3028:"Exempt",
3029:"Exempt",
3041:"Exempt",
4042:"Exempt",
3043:"Exempt",
3044:"Exempt",
3045:"Exempt",
3046:"Exempt",
3047:"Exempt",
3048:"Exempt",
3049:"Exempt",
3061:"5L",
3062:"3",
3063:"3",
3064:"5H",
3065:"3",
3066:"3",
3081:"5L",
3082:"3",
3083:"3",
3084:"5H",
3085:"3",
3086:"3",
3087:"5H",
3088:"3",
3089:"3",
3101:"5L",
3102:"3",
3103:"3",
3104:"5H",
3105:"3",
3106:"3",
3107:"5H",
3108:"3",
3109:"3",
3121:"5L",
3122:"3",
3123:"3",
3124:"5H",
3125:"3",
3126:"3",
3127:"5H",
3128:"3",
3129:"3",
3140:"3",
3142:"3",
3161:"3",
3163:"3",
3181:"3",
3182:"3",
3183:"3",
3201:"Exempt",
3202:"Exempt",
3203:"Exempt",
3204:"Exempt",
3205:"Exempt",
3206:"Exempt",
3221:"5L",
3222:"3",
3223:"3",
3224:"5H",
3225:"3",
3226:"3",
3401:"2",
3402:"3",
3403:"3",
3404:"2",
3405:"3",
3406:"3",
3407:"2",
3408:"3",
3409:"3",
3410:"2",
3411:"2",
3412:"2",
3413:"3",
3414:"3",
3415:"3",
3416:"3",
3417:"3",
3418:"3",
3421:"2",
3422:"3",
3423:"3",
3424:"2",
3425:"3",
3426:"3",
3427:"2",
3428:"3",
3429:"3",
3430:"2",
3431:"3",
3432:"3",
3434:"3",
3435:"3",
3436:"2",
3437:"3",
3438:"3",
3441:"2",
3442:"3",
3443:"3",
3444:"2",
3445:"3",
3446:"3",
3447:"2",
3448:"3",
3449:"3",
3450:"2",
3451:"3",
3452:"3",
3453:"2",
3455:"3",
3456:"2",
3457:"3",
3458:"3",
3461:"3",
3462:"3",
3463:"3",
3481:"3",
3482:"3",
3483:"3",
3501:"3",
3502:"3",
3503:"3",
3521:"Exempt",
3522:"Exempt",
3523:"Exempt",
3524:"Exempt",
3525:"Exempt",
3541:"Exempt",
3542:"Exempt",
3543:"Exempt",
3544:"Exempt",
3545:"Exempt",
3801:"2",
3802:"3",
3803:"3",
3821:"3",
3822:"3",
3823:"3",
3841:"3",
3842:"3",
3843:"3",
3861:"2",
3862:"3",
3863:"3",
3864:"2",
3865:"3",
3866:"3",
3867:"2",
3868:"3",
3869:"3",
3901:"3",
3902:"3",
3903:"3",
4201:"4B",
4202:"3",
4203:"3",
4204:"4B",
4205:"3",
4206:"3",
4221:"4B",
4222:"3",
4223:"3",
4224:"4B",
4225:"3",
4226:"3",
4241:"4B",
4242:"3",
4243:"3",
4244:"4B",
4245:"3",
4246:"3",
4261:"3",
4262:"3",
4263:"3",
4264:"3",
4265:"3",
4266:"3",
4281:"3",
4282:"3",
4283:"3",
4284:"3",
4285:"3",
4286:"3",
4301:"4C",
4302:"4C",
4321:"4B",
4322:"3",
4323:"3",
4324:"4B",
4325:"3",
4326:"3",
4341:"Exempt",
4342:"Exempt",
4343:"Exempt",
4344:"Exempt",
4345:"Exempt",
4346:"Exempt",
4361:"4B",
4362:"3",
4363:"3",
4364:"4B",
4365:"3",
4366:"3",
4601:"3",
4602:"3",
4603:"3",
4621:"Exempt",
4622:"Exempt",
4623:"Exempt",
4801:"Exempt",
4802:"Exempt",
4803:"Exempt",
4804:"Exempt",
4805:"Exempt",
4806:"Exempt",
4807:"Exempt",
4808:"Exempt",
4809:"Exempt",
4821:"2",
4822:"3",
4823:"3",
4824:"2",
4825:"3",
4826:"3",
4827:"2",
4828:"3",
4829:"3",
4841:"3",
4842:"3",
4843:"3",
4861:"2",
4862:"3",
4863:"3",
4864:"2",
4865:"3",
4866:"3",
4867:"2",
4868:"3",
4869:"3",
4881:"3",
4882:"3",
4883:"3",
4901:"Exempt",
4902:"Exempt",
4903:"Exempt",
5000:"8A",
5001:"7",
5002:"7",
5003:"8A",
5004:"7",
5005:"7",
5006:"7",
5007:"7",
5008:"7",
5009:"7",
5010:"7",
5011:"7",
5013:"8A",
5014:"7",
5015:"7",
5016:"Exempt",
5017:"Exempt",
5018:"Exempt",
5019:"8A",
5020:"7",
5021:"7",
5022:"7",
5023:"7",
5024:"7",
5025:"7",
5026:"7",
5027:"7",
5028:"Exempt",
5029:"Exempt",
5030:"Exempt",
5031:"7",
5032:"7",
5033:"7",
5034:"Exempt",
5035:"Exempt",
5036:"Exempt",
5037:"Exempt",
5038:"Exempt",
5039:"Exempt",
5040:"Exempt",
5041:"Exempt",
5042:"Exempt",
5043:"Exempt",
5044:"Exempt",
5045:"Exempt",
5046:"6",
5047:"7",
5048:"7",
5049:"7",
5050:"7",
5051:"7",
5052:"Exempt",
5053:"Exempt",
5054:"Exempt",
5055:"Exempt",
5056:"Exempt",
5057:"Exempt",
5058:"Exempt",
5059:"Exempt",
5060:"Exempt",
5061:"Exempt",
5062:"Exempt",
5063:"Exempt",
5064:"Exempt",
5065:"Exempt",
5066:"Exempt",
5067:"Exempt",
5068:"Exempt",
5069:"Exempt",
5070:"Exempt",
5071:"Exempt",
5072:"Exempt",
5073:"6",
5074:"7",
5075:"7",
5076:"6",
5077:"7",
5078:"7",
5079:"6",
5080:"7",
5081:"7",
5082:"6",
5083:"6",
5084:"6",
5085:"6",
5086:"6",
5087:"6",
5088:"6",
5090:"7",
5091:"7",
5093:"6",
5094:"6",
5095:"6",
5096:"7",
5097:"7",
5098:"6",
5100:"6",
5101:"7",
5102:"7",
5103:"7",
5104:"7",
5105:"7",
5106:"7",
5108:"7",
5109:"7",
5110:"7",
5111:"Exempt",
5112:"Exempt",
5113:"Exempt",
5114:"Exempt",
5115:"Exempt",
5116:"Exempt",
5117:"6",
5118:"7",
5119:"7",
5120:"6",
5121:"6",
5122:"6",
5123:"Exempt",
5124:"Exempt",
5125:"Exempt",
5126:"Exempt",
5127:"Exempt",
5128:"Exempt",
5129:"Exempt",
5130:"Exempt",
5131:"Exempt",
5132:"Exempt",
5133:"Exempt",
5134:"Exempt",
5135:"Exempt",
5136:"Exempt",
5137:"Exempt",
5138:"Exempt",
5139:"Exempt",
5140:"Exempt",
5141:"Exempt",
5142:"Exempt",
5143:"Exempt",
5144:"Exempt",
5145:"Exempt",
5146:"Exempt",
5147:"Exempt",
5148:"Exempt",
5149:"Exempt",
5150:"9L",
5151:"7",
5152:"7",
5153:"9H",
5154:"7",
5155:"7",
5156:"9L",
5157:"7",
5158:"7",
5159:"9H",
5160:"7",
5161:"7",
5162:"9H",
5163:"7",
5164:"7",
5165:"9L",
5166:"7",
5167:"7",
5168:"9H",
5169:"7",
5170:"7",
5171:"9H",
5172:"7",
5173:"7",
5174:"9L",
5175:"7",
5176:"7",
5177:"9H",
5178:"7",
5179:"7",
5180:"9H",
5181:"7",
5182:"7",
5183:"7",
5184:"7",
5185:"7",
5186:"7",
5187:"7",
5188:"7",
5189:"7",
5190:"7",
5191:"7",
5192:"Exempt",
5193:"Exempt",
5194:"Exempt",
5195:"Exempt",
5196:"Exempt",
5197:"Exempt",
5198:"9L",
5199:"7",
5200:"7",
5201:"9H",
5202:"7",
5203:"7",
5204:"6",
5205:"7",
5206:"7",
5207:"6",
5208:"7",
5209:"7",
5210:"6",
5211:"7",
5212:"7",
5213:"6",
5214:"6",
5215:"7",
5216:"7",
5217:"7",
5218:"7",
5219:"6",
5220:"7",
5221:"7",
5222:"6",
5223:"7",
5224:"7",
5225:"6",
5226:"7",
5227:"7",
5228:"6",
5229:"7",
5230:"7",
5231:"6",
5232:"7",
5233:"7",
5234:"6",
5235:"7",
5236:"7",
5237:"6",
5238:"7",
5239:"7",
5240:"6",
5241:"7",
5242:"7",
5243:"6",
5244:"7",
5245:"7",
5246:"6",
5247:"7",
5248:"7",
5249:"7",
5250:"7",
5251:"7",
5252:"7",
5253:"7",
5254:"7",
5255:"7",
5256:"7",
5257:"7",
5258:"Exempt",
5259:"Exempt",
5260:"Exempt",
5261:"Exempt",
5262:"Exempt",
5263:"Exempt",
5264:"Exempt",
5265:"Exempt",
5266:"Exempt",
5267:"Exempt",
5268:"6",
5269:"7",
5270:"7",
5271:"7",
5272:"7",
5273:"7",
5274:"7",
5275:"7",
5276:"7",
5277:"6",
5278:"7",
5279:"7",
5280:"6",
5281:"7",
5282:"7",
5283:"6",
5284:"7",
5285:"7",
5286:"7",
5287:"7",
5288:"7",
5289:"8B",
5290:"7",
5291:"7",
5292:"8B",
5293:"7",
5294:"7",
5295:"8B",
5296:"7",
5297:"7",
5298:"8B",
5299:"7",
5300:"7",
5301:"8B",
5302:"7",
5303:"7",
5304:"8B",
5305:"7",
5306:"7",
5307:"7",
5308:"7",
5309:"7",
5310:"7",
5311:"7",
5312:"7",
5313:"7",
5314:"7",
5315:"7",
5316:"7",
5317:"7",
5318:"7",
5320:"8B",
5321:"7",
5322:"7",
5323:"8B",
5324:"7",
5325:"7",
5326:"Exempt",
5327:"Exempt",
5328:"Exempt",
5329:"Exempt",
5330:"Exempt",
5331:"Exempt",
5332:"8B",
5333:"7",
5334:"7",
5335:"8B",
5336:"7",
5337:"7",
5338:"7",
5339:"7",
5340:"7",
5341:"Exempt",
5342:"Exempt",
5343:"Exempt",
5344:"Exempt",
5345:"Exempt",
5346:"Exempt",
5347:"Exempt",
5348:"Exempt",
5349:"Exempt",
5350:"Exempt",
5351:"Exempt",
5352:"Exempt",
5353:"6",
5354:"7",
5355:"7",
5356:"6",
5357:"7",
5358:"7",
5359:"6",
5360:"7",
5361:"7",
5362:"7",
5363:"7",
5364:"7",
5365:"6",
5366:"7",
5367:"7",
5368:"6",
5369:"7",
5370:"7",
5371:"6",
5372:"7",
5373:"7",
5374:"7",
5375:"7",
5376:"7",
5377:"Exempt",
5378:"Exempt",
5379:"Exempt",
5380:"8A",
5381:"7",
5382:"7",
5401:"Exempt",
5402:"Exempt",
5403:"Exempt",
5404:"Exempt",
5405:"Exempt",
5406:"Exempt",
5407:"7",
5408:"7",
5409:"7",
5410:"7",
5411:"7",
5412:"7",
5413:"Exempt",
5414:"Exempt",
5415:"Exempt",
5416:"Exempt",
5417:"Exempt",
5418:"Exempt",
5419:"7",
5420:"7",
5421:"7",
5422:"7",
5423:"7",
5424:"7",
5425:"Exempt",
5426:"Exempt",
5427:"Exempt",
5428:"Exempt",
5429:"Exempt",
5430:"Exempt",
5431:"Exempt",
5432:"Exempt",
5433:"Exempt",
5434:"Exempt",
5435:"Exempt",
5436:"Exempt",
5437:"Exempt",
5438:"Exempt",
5439:"Exempt",
5440:"Exempt",
5441:"Exempt",
5442:"Exempt",
5443:"6",
5444:"7",
5445:"7",
5446:"6",
5447:"7",
5448:"7",
5500:"Exempt",
5501:"Exempt",
5502:"Exempt",
5600:"Exempt",
5601:"Exempt",
5602:"Exempt",
5603:"Exempt",
5700:"2",
5701:"6",
6000:"4C",
6001:"3",
6002:"3",
6003:"8C",
6004:"7",
6005:"7",
6006:"4C",
6007:"3",
6008:"3",
6009:"8C",
6010:"7",
6011:"7",
6012:"4C",
6013:"3",
6014:"3",
6015:"8C",
6016:"7",
6017:"7",
6018:"4C",
6019:"3",
6020:"3",
6021:"8C",
6022:"7",
6023:"7",
6024:"4C",
6025:"3",
6026:"3",
6027:"8C",
6028:"7",
6029:"7",
6030:"Exempt",
6031:"Exempt",
6032:"Exempt",
6033:"Exempt",
6034:"Exempt",
6035:"Exempt",
6036:"3",
6037:"3",
6038:"3",
6039:"7",
6040:"7",
6041:"7",
6042:"3",
6043:"3",
6044:"3",
6045:"7",
6046:"7",
6047:"7",
6100:"2",
6101:"2",
6102:"2",
6103:"3",
6104:"3",
6105:"3",
6106:"2",
6107:"2",
6108:"2",
6109:"2",
6110:"2",
6111:"2",
6112:"2",
6113:"3",
6114:"3",
6115:"3",
6116:"3",
6117:"3",
6118:"3",
6119:"2",
6120:"2",
6121:"2",
6122:"7",
6123:"7",
6124:"7",
6125:"7",
6126:"7",
6127:"7",
6128:"2",
6129:"2",
6130:"2",
6131:"3",
6132:"3",
6133:"2",
6134:"2",
6135:"2",
6136:"7",
6137:"7",
6138:"7",
6139:"7",
6140:"2",
6141:"2",
6142:"2",
6143:"2",
6144:"3",
6145:"3",
6146:"7",
6147:"7",
6148:"3",
6149:"3",
6150:"7",
6151:"7",
6152:"Exempt",
6153:"Exempt",
6154:"Exempt",
6155:"Exempt",
6156:"Exempt",
6157:"Exempt",
7001:"Exempt",
7002:"Exempt",
7003:"Exempt",
7004:"Exempt",
7005:"Exempt",
7006:"Exempt",
7007:"Exempt",
7008:"Exempt",
7009:"Exempt",
7010:"Exempt",
7011:"Exempt",
7012:"Exempt",
7013:"Exempt",
7014:"Exempt",
7015:"Exempt",
7016:"Exempt",
7017:"Exempt",
7018:"Exempt",
7019:"Exempt",
7020:"Exempt",
7021:"Exempt",
7022:"Exempt",
7023:"Exempt",
7024:"Exempt",
7025:"Exempt",
7026:"Exempt",
7027:"Exempt",
7028:"Exempt",
7029:"Exempt",
7030:"Exempt",
7031:"Exempt",
7032:"Exempt",
7033:"Exempt",
7034:"Exempt",
7035:"Exempt",
7036:"Exempt",
7037:"Exempt",
7038:"Exempt",
7039:"Exempt",
7040:"Exempt",
7041:"Exempt",
7042:"Exempt",
7043:"Exempt",
7044:"Exempt",
7045:"Exempt",
7046:"Exempt",
7047:"Exempt",
7048:"Exempt",
7049:"Exempt"
};