import {filterLandingHide} from './filter.js'

export function initialiseActiveTab() {
  // Check if we are on a page with tabs
  if (document.querySelector(".tab-nav__wrapper")) {
    // Check URL for "tab" param
    /* global URLSearchParams (includes polyfill for IE11) */
    const selectedTab = new URLSearchParams(document.location.search).get("tab")

    if (isValidTab(selectedTab)) {
      filterLandingHide()
      updateSelectedTab(selectedTab)
      updateFilterVisibility(selectedTab)
      updateSelectedContent(selectedTab)
    } else {
      setDefaultTabAndContent()
    }
  }
}

export function initialiseDownloadJumpLink(selectedTab, jumpTarget, checkVisibility) {
  let link = document.getElementById('download-discussion-documents');
  if (link) {
    document.getElementById('download-discussion-documents').addEventListener('click', function(event) {
      checkVisibility();
      changeTab(event, selectedTab);
      location.hash = "#" + jumpTarget;
    });
  }
}

export function changeTab(event, selectedTab) {
  if(event != null) {
    event.preventDefault()
  }
  if (isValidTab(selectedTab)) {
    updateURL(selectedTab)
    updateSelectedTab(selectedTab)
    updateFilterVisibility(selectedTab)
    updateSelectedContent(selectedTab)
    window.sya.updateStickyElements()
    window.scrollTo( 0, $('.tab-js-stickybit-parent').offset().top)
  } else {
    setDefaultTabAndContent()
    window.sya.updateStickyElements()
  }
}

function isValidTab(selectedTab) {
  const navItems = document.querySelectorAll('.tab-nav__item')
  for (let i = 0; i < navItems.length; i++) {
    if (navItems[i].dataset.tab === selectedTab) {
      return true;
    }
  }
  return false;
}

// Update URL
function updateURL(selectedTab) {
  var urlSearchParams = new URLSearchParams(document.location.search);
  urlSearchParams.set("tab", selectedTab)
  if (window.history.pushState) {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlSearchParams.toString();
    window.history.pushState({path:newurl},'',newurl);
  }
}

// Update selected tab
function updateSelectedTab(selectedTab) {
  const navItems = document.querySelectorAll('.tab-nav__item')
  for (let i = 0; i < navItems.length; i++) {
    navItems[i].classList.remove("tab-nav__item--active")
    navItems[i].removeAttribute("aria-selected")

    if(navItems[i].dataset.tab === selectedTab) {
      navItems[i].classList.add("tab-nav__item--active")
      navItems[i].setAttribute("aria-selected", "true")
    }
  };
}

// Show/Hide filter depending on the selected tab
function updateFilterVisibility(selectedTab) {
  const navItems = document.querySelectorAll('.tab-nav__item')
  const filterPresent = document.querySelector('.filter')

  // Check if filter is in DOM
  if (filterPresent) {
    for (let i = 0; i < navItems.length; i++) {
      // Check if the filter should be shown for that tab
      if (navItems[i].dataset.tab === selectedTab) {
        if (navItems[i].classList.contains("js-filter-show")) {
          filterPresent.classList.add("filter--show")
        } else {
          filterPresent.classList.remove("filter--show")
        }
      }
    }
  }
}

// Show content for selected tab
function updateSelectedContent(selectedTab) {
  const contentItems = document.querySelectorAll('.tab-content__item')
  for (let i = 0; i < contentItems.length; i++) {
    contentItems[i].classList.remove("tab-content__item--active")
    contentItems[i].setAttribute("aria-hidden","true")

    if(contentItems[i].dataset.tab === selectedTab) {
      contentItems[i].classList.add("tab-content__item--active")
      contentItems[i].removeAttribute("aria-hidden")
    }
  };
}

// Default to the first tab and content item
function setDefaultTabAndContent() {
  const navItems = document.querySelectorAll('.tab-nav__item')
  const contentItems = document.querySelectorAll('.tab-content__item')
  if(navItems){
    navItems[0].classList.add('tab-nav__item--active')
    updateSelectedContent(/*global tab*/navItems[0].dataset.tab);
  }
  if(contentItems.length !== 0) {
    contentItems[0].classList.add('tab-content__item--active')
  }

  // Set filter visibility
  const filterPresent = document.querySelector('.filter')

  // Check if filter is in DOM
  if (filterPresent) {
    if (navItems[0].classList.contains("js-filter-show")) {
      filterPresent.classList.add("filter--show")
    } else {
      filterPresent.classList.remove("filter--show")
    }
  }
  if (filterPresent) {
    if (navItems[0].classList.contains("js-filter-show")) {
      filterPresent.classList.add("filter--show")
    } else {
      filterPresent.classList.remove("filter--show")
    }
  }

  // Remove tab from URL
  var urlSearchParams = new URLSearchParams(document.location.search)

  if (window.history.pushState && urlSearchParams.get('tab')) {
    urlSearchParams.delete("tab")
    let param = ""
    if (urlSearchParams.toString()) {
      param = "?" + urlSearchParams.toString()
    }
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + param
    window.history.pushState({path:newurl},'',newurl)
  }
}
