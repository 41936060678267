export function initialiseAccessibility() {
  window.addEventListener('keydown', handleFirstTab);

  $('.js-skiplinks').on('keydown',function(e){
    if ((e.keyCode || e.which) == 13) {
      var href = $(this).attr('href').split("#");
      $('#'+href[1]).first().focus();
      e.preventDefault();
    }
  });
}

// Adds a class to the body to indicate that the user is navigativing with the keyboard
function handleFirstTab(e) {
  if (e.keyCode === 9) { // tab key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', handleFirstTab);
  }
}
