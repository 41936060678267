export default {
  toggleMenu: function() {
    if(document.getElementsByClassName('header__mobile-menu-toggle--open').length > 0){
      document.getElementsByClassName('header__mobile-menu-toggle')[0].classList.remove("header__mobile-menu-toggle--open");
      document.getElementsByClassName('header__nav')[0].classList.remove("header__nav--open");
    } else{
      document.getElementsByClassName('header__mobile-menu-toggle')[0].classList.add("header__mobile-menu-toggle--open");
      document.getElementsByClassName('header__nav')[0].classList.add("header__nav--open");
    }
  }
}