import { generatePDF } from "./generatePDF";

export function getForm() {
  const selected = $(".js-form-dropdown").find(":checked");
  const ajaxForm = $(".js-ajax-form");
  const formLoading = $(".js-form-loading");
  const relatedLinks = $(".js-related-links");

  if (selected.length == 0) {
    return;
  }

  // clear previous ajaxForm
  ajaxForm.empty();
  relatedLinks.empty();
  formLoading.addClass("form__loader--loading");

  $.ajax({
    url: `${window.location.origin}${selected.data("link")}/TopicForm`,
  })
    .done((result) => {
      gtag("event", "Start", {
        event_category: "Form - full form",
        event_label: selected.text(),
      });
      // append the form to the ajaxForm div
      ajaxForm.html(result);

      const formElement = $(".userform")[0];
      const form = $(".userform");
      let submitButton = form.find("input[type=submit]");
      let submitLabel = submitButton.val();
      submitButton.addClass("button button--primary");
      formLoading.removeClass("form__loader--loading");

      // update the form with the selected form, chosen from the dropdown
      updateAjaxForm(form, selected);

      // check if the button is disabled before submitting the form, stopping multiple submissions
      submitButton.on("click", function (e) {
        e.preventDefault();

        if (!submitButton.disabled) {
          submitButton.disabled = true;
          form.submit();
        }
      });

      // Prevent normal submission of user defined form
      form.on("submit", function (e) {
        e.preventDefault();
        let thisForm = $(this);

        // add disabled attribute when submitting
        handleRecaptchaAndSubmit(
          submitButton,
          thisForm,
          form,
          formElement,
          selected,
          submitLabel
        );
      });
    })
    .fail((err) => {
      console.error("Error with TopicForm submission: " + err.status);
    });
}

// Handle reCAPTCHA and submit the form
function handleRecaptchaAndSubmit(
  submitButton,
  thisForm,
  form,
  formElement,
  selected,
  submitLabel
) {
  let noCaptchaField = $(formElement).find('*[name="g-recaptcha-response"]');

  submitButton.attr({ value: "Submitting..." });

  // Create an array of form data. Used for generating a PDF
  const formDataArray = thisForm.serializeArray();

  //remove previous validations
  form.find(".message").filter(".validation,.required,.error").remove();

  // Execute reCAPTCHA if it is present, otherwise submit the form
  if (noCaptchaField.length > 0) {
    ajaxNoCaptcha(formElement)
      .then(() => {
        handleSubmit(
          thisForm,
          formElement,
          form,
          selected,
          formDataArray,
          submitButton,
          submitLabel
        );
      })
      .catch((error) => {
        console.error("Error executing reCAPTCHA: ", error);
      });
  } else {
    handleSubmit(
      thisForm,
      formElement,
      form,
      selected,
      formDataArray,
      submitButton,
      submitLabel
    );
  }
}

// Submit the form via AJAX
function handleSubmit(
  thisForm,
  formElement,
  form,
  selected,
  formDataArray,
  submitButton,
  submitLabel
) {
  $.ajax({
    url: thisForm.attr("action"),
    type: thisForm.attr("method"),
    data: thisForm.serialize(),
    success: function (resultHTML) {
      let parsedHTML = $($.parseHTML(resultHTML));
      const formBlock = $(".js-form-block");
      const thanksBlock = $(".js-thanks-block");

      // Replace each .nocaptcha field with the corresponding .nocaptcha element in parsedHTML
      if ($(formElement).find('*[name="g-recaptcha-response"]').length > 0) {
        try {
          parsedHTML.find(".nocaptcha").each(function (index, item) {
            let id = $(item).attr("id");
            if (form.find(".nocaptcha")) {
              form.find(".nocaptcha").replaceWith(item);
            }
          });
        } catch (e) {
          // Ignores a ReferenceError from grecaptcha if it is not loaded/enabled
          if (e.name != "ReferenceError") {
            throw e;
          }
        }
      }

      // process error / validation messages
      if (parsedHTML.hasClass("validationerror")) {
        const errorMessages = parsedHTML
          .find(".message")
          .filter(".validation,.required,.error");
        // Error
        errorMessages.each(function () {
          //need to show validation for both captcha and normal inputs
          const parent = $(this).parent();
          const parentID = parent.attr("id");
          const targetElement = form.find("#" + parentID);

          if (targetElement.length == 0) {
            console.error(`No element with ID ${parentID} found in the form.`);
            return;
          }

          targetElement.append($(this));
          // add error class to the input (red border)
          // the input name has the same value as parent.attr('id'))
          targetElement
            .find('input[name="' + parentID + '"]')
            .addClass("error");
        });
        clearInputError(form);
      } else {
        // No error - continue
        gtag("event", "Success", {
          event_category: "Form - full form",
          event_label: selected.text(),
        });

        //get related topics
        getRelatedTopics(selected);

        // generate PDF
        generatePDF(selected.text(), formDataArray);

        //currently the return will be generic message from siteconfig
        formBlock.addClass("u-hidden");
        thanksBlock.removeClass("u-hidden");
      }

      // remove disabled attribute after submit
      submitButton.disabled = false;
      submitButton.attr({ value: submitLabel });
    },
  });
}

function getRelatedTopics(selected) {
  $(".js-related-wrapper").addClass("u-hidden");
  $.ajax({
    url: window.location.origin + selected.data("link") + "/RelatedTopicList",
  })
    .done(function (result) {
      const topics = JSON.parse(result);

      if (topics.length > 0) {
        $(".js-related-wrapper").removeClass("u-hidden");
        topics.forEach(function (topic) {
          $(".js-related-links").append(
            "<a href='" + topic.Link + "'>" + topic.Title + "</a>"
          );
        });
      }
    })
    .fail(function (err) {
      console.log("Error with related topics: " + err.status);
    });
}

// Update the form with the selected form, inside ajaxForm
function updateAjaxForm(form, selected) {
  const SELECT2_OPTIONS = { minimumResultsForSearch: -1, width: "100%" };
  const formAction = `${selected.data("link")}/Form`;

  form.find("select").select2(SELECT2_OPTIONS);
  form.attr("action", formAction);
}

export function clearInputError(form) {
  form.find("input").each(function (index, item) {
    $(item).focus(function () {
      $(this).removeClass("error");
      $(this).closest("div.field").find("span.message").remove();
    });
  });
}

// Function to execute reCAPTCHA
export function ajaxNoCaptcha(formElement) {
  let noCaptchaField = $(formElement).find('*[name="g-recaptcha-response"]')[0];

  return new Promise((resolve, reject) => {
    try {
      if (!noCaptchaField) {
        throw new Error('No element with name "g-recaptcha-response" found in the form.');
      }

      window.noCaptchaFieldRenderId = window.noCaptchaFieldRenderId
        ? window.noCaptchaFieldRenderId
        : 1;
      const grecaptchaId = "noCaptchaFieldRender" + noCaptchaFieldRenderId++;
      let script = document.createElement("script");

      window[grecaptchaId] = function () {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(noCaptchaField.getAttribute("data-sitekey"), {
              action: "submit",
            })
            .then(function (token) {
              noCaptchaField.value = token;
              resolve();
            })
            .catch(function (error) {
              console.error("Error executing reCAPTCHA: ", error);
              reject(error);
            });
        });
      };

      script.type = "text/javascript";
      script.src =
        "https://www.google.com/recaptcha/api.js?render=explicit&hl=en&onload=" +
        grecaptchaId;
      document.head.appendChild(script);
    } catch (e) {
      if (e.name == "ReferenceError") {
        // Skip executing reCAPTCHA if it is not loaded (e.g. disabled for form in CMS)
        resolve();
      } else {
        reject(e);
      }
    }
  });
}

export function downloadFeedback(e) {
  e.preventDefault();
  if (window.feedbackPDF) {
    window.feedbackPDF.save("feedback.pdf");
  }
}
