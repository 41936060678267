export function toggleReadMore(feedbackID) {

  const feedbackCard = document.getElementById(feedbackID)

  if (feedbackCard) {

		const feedbackCardContent = feedbackCard.querySelector(".feedback-card__content")
		const feedbackCardButton = feedbackCard.querySelector(".feedback-card__button")
		const feedbackCardExpand = feedbackCardButton.querySelector(".feedback-card__button-expand")
		const feedbackCardCollapse = feedbackCardButton.querySelector(".feedback-card__button-collapse")

		if (feedbackCardContent && feedbackCardButton && feedbackCardExpand && feedbackCardCollapse) {
			if (feedbackCardButton.getAttribute("aria-expanded") === "false") { // Expand feedbackCard
				feedbackCardButton.setAttribute("aria-expanded", "true")
				feedbackCardContent.setAttribute("aria-hidden", "false")
				feedbackCardContent.classList.add("feedback-card__content--extended")
				feedbackCardContent.classList.remove("feedback-card__content--limited")
				feedbackCardExpand.classList.remove("feedback-card__button-expand--show")
				feedbackCardExpand.setAttribute("aria-hidden", "true")
				feedbackCardCollapse.classList.add("feedback-card__button-collapse--show")
				feedbackCardCollapse.setAttribute("aria-hidden", "false")
				window.sya.updateStickyElements()
			} else if (feedbackCardButton.getAttribute("aria-expanded") === "true") { // Collapse feedbackCard
				feedbackCardButton.setAttribute("aria-expanded", "false")
				feedbackCardContent.classList.remove("feedback-card__content--extended")
				feedbackCardContent.setAttribute("aria-hidden", "true")
				feedbackCardExpand.classList.add("feedback-card__button-expand--show")
				feedbackCardExpand.setAttribute("aria-hidden", "false")
				feedbackCardCollapse.classList.remove("feedback-card__button-collapse--show")
				feedbackCardCollapse.setAttribute("aria-hidden", "true")
				feedbackCardContent.classList.add("feedback-card__content--limited")
				window.sya.updateStickyElements()
				window.scrollTo( 0, ($(feedbackCard).offset().top)-60)
			}
		}
	}
}