export function initialiseFeedbackVisualisation() {
  const $feedbackChart = $('.js-feedback-visualisation');
  if ($feedbackChart.length) {
    const percent = $feedbackChart.data("percent");

    let feedbackChartGrey = "#a7a9ac";
    let feedbackChartBlue = "#005E8F";


    let feedbackChartBackgroundColour = feedbackChartGrey;
    let feedbackChartSlice1Colour = feedbackChartBlue;
    let feedbackChartSlice2Colour = feedbackChartBlue;
    const deg = (percent/100*360) + "deg";
    let deg1 = "90deg";
    let deg2 = deg;

    // If percentage is less than 50%
    if (percent < 50) {
      feedbackChartBackgroundColour = feedbackChartBlue;
      feedbackChartSlice1Colour = feedbackChartGrey;
      feedbackChartSlice2Colour = feedbackChartGrey;
      deg1 = (percent/100*360+90) + "deg";
      deg2 = "0deg";
    }

    $feedbackChart.css({"background": feedbackChartBackgroundColour});
    $feedbackChart.find(".js-feedback-visualisation-slice-1").css({"transform": `rotate(${deg1})`, "-webkit-transform": `rotate(${deg1})`, "background": feedbackChartSlice1Colour});
    $feedbackChart.find(".js-feedback-visualisation-slice-2").css({"transform": `rotate(${deg2})`, "-webkit-transform": `rotate(${deg2})`, "background": feedbackChartSlice2Colour});
  }
}