import stickybits from "stickybits";
import tippy from "tippy.js";
import share from "./modules/share.js";
import "url-search-params-polyfill";
import {
  initialiseActiveTab,
  changeTab,
  initialiseDownloadJumpLink,
} from "./modules/tabs.js";
import menu from "./modules/menu.js";
import {
  filter,
  toggleFilterOverlay,
  checkFilterLandingVisiability,
} from "./modules/filter.js";
import { getForm, downloadFeedback } from "./modules/ajaxForm.js";
import {
  calculateEarnersLevy,
  initialiseELClickAndKeypressEnter,
  startOverCalculationEarnersLevy,
  validateNumber,
} from "./modules/earners-levy/earners-levy.js";
import { initialiseAccessibility } from "./modules/accessibility.js";
import select2 from "./vendors/select2.js";
import { accordionToggle } from "./modules/accordion.js";
import { toggleReadMore } from "./modules/feedback-card.js";
import { loadMoreFeedback } from "./modules/feedback-cards-toggle.js";
import {
  initialiseMVLKeypressEnter,
  searchNumberPlate,
  startOverCalculationMotorLevy,
  validatePlate,
} from "./modules/motor-vehicle-levy-tool.js";
import {
  calcFooterOffset,
  truncateTopicCardContent,
  truncateDiscussionCardContent,
} from "./modules/common.js";
import { initialiseQuickForm, closeQuickForm } from "./modules/quick-form.js";
import { contentLinksInit, contentTableWrapper } from "./modules/content.js";
import { googleAnalyticsInit } from "./modules/ga.js";
import { initialiseFeedbackVisualisation } from "./modules/feedback-data-visualisation.js";

(function () {
  //global
  window.sya = {
    accordionToggle: accordionToggle,
    calculateEarnersLevy: calculateEarnersLevy,
    changeTab: changeTab,
    closeQuickForm: closeQuickForm,
    copyUrl: share.copyUrl,
    downloadFeedback: downloadFeedback,
    filter: filter,
    loadMoreFeedback: loadMoreFeedback,
    startOverCalculationEarnersLevy: startOverCalculationEarnersLevy,
    startOverCalculationMotorLevy: startOverCalculationMotorLevy,
    searchNumberPlate: searchNumberPlate,
    toggleFilterOverlay: toggleFilterOverlay,
    toggleMenu: menu.toggleMenu,
    toggleReadMore: toggleReadMore,
    toggleShare: share.toggleShare,
    truncateTopicCardContent: truncateTopicCardContent,
    validateNumber: validateNumber,
    validatePlate: validatePlate,
  };

  calcFooterOffset();
  $(window).resize(function () {
    calcFooterOffset();
    truncateTopicCardContent();
    truncateDiscussionCardContent();
  });

  //form
  $(".js-form-dropdown").change(function () {
    getForm();
  });

  let prevFocus;
  $(".js-toggle-feedback-form").click(function () {
    //if there is no form then get current selection on open
    if ($(".js-ajax-form form").length == 0) {
      getForm();
    }

    if ($(".js-form-slider").hasClass("form-slider--show")) {
      prevFocus.focus();
      $(".js-form-slider").removeClass("form-slider--show");
      $(".js-form-slider").attr("aria-hidden", "true");
      $("body").removeClass("u-noscroll--until-laptop");

      //if form is hidden and you are closing then empty
      if ($(".js-form-block").hasClass("u-hidden")) {
        $(".js-ajax-form").empty();
        $(".js-form-block").removeClass("u-hidden");
        $(".js-thanks-block").addClass("u-hidden");
      }
    } else {
      prevFocus = $(this);
      $(".js-form-slider").addClass("form-slider--show");
      $(".js-form-slider").attr("aria-hidden", "false");
      $("body").addClass("u-noscroll--until-laptop");
      $(".js-form-slider").find(".js-toggle-feedback-form").focus();
    }
  });

  $(".js-select2").select2({
    minimumResultsForSearch: -1,
    width: "100%",
  });

  // link the select2 to the slider when inside a slider (see: https://select2.org/troubleshooting/common-problems)
  $("div.js-form-slider .js-select2").select2("destroy");
  $("div.js-form-slider .js-select2").select2({
    minimumResultsForSearch: -1,
    width: "100%",
    dropdownParent: $("#js-form-feedback"),
  });

  checkFilterLandingVisiability();
  initialiseActiveTab();
  initialiseDownloadJumpLink(
    "about",
    "supporting-documents",
    checkFilterLandingVisiability
  );
  initialiseAccessibility();
  initialiseQuickForm();
  initialiseELClickAndKeypressEnter();
  initialiseMVLKeypressEnter();
  initialiseFeedbackVisualisation();

  const stickyElements = stickybits(".js-sticky");

  const stickyTopicFeedback = stickybits(".js-sticky-quick-feedback", {
    stickyBitStickyOffset: 90,
  });

  window.sya.updateStickyElements = function () {
    stickyElements.update();
    if (stickyTopicFeedback) {
      stickyTopicFeedback.update();
    }
  };

  tippy("[data-tippy-content]", {
    theme: "sya",
    animation: "fade",
    arrow: true,
  });

  truncateTopicCardContent();
  truncateDiscussionCardContent();
  contentLinksInit();
  contentTableWrapper();
  googleAnalyticsInit();
})();
