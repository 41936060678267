export function googleAnalyticsInit(){
  if (typeof ga == "undefined"){
    return false;
  }

	//file/document link clicks
	$('.js-ga-file-link').on('click', function(e){
		gtag('event', "Click - download", {
		  event_category: "Download - File link",
		  event_label: $(this).attr('href')
		});
	});
	
//example tool
		// gtag('event', "Submit - estimate", {
		//   event_category: "Tool - levy estimator",
		//   event_label: window.location.pathname
		// });
	
}