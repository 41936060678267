export function setLiableEarnings(liableEarnings, maxEarnings, minEarnings) {

  if (liableEarnings > maxEarnings) {
    
    return parseFloat(maxEarnings);

  } else if (liableEarnings < minEarnings) {
    
    return parseFloat(minEarnings);

  } else {

    return parseFloat(liableEarnings);

  }

}