import { setLiableEarnings } from './set-liable-earnings.js'

export function validateNumber() {
  let liableEarnings = document.getElementById("liable-earnings-input").value;
  liableEarnings = parseFloat(liableEarnings);
  
  if (liableEarnings == "" || isNaN(liableEarnings) || earnersLevyObj == undefined) {
    let errorMessage = document.getElementsByClassName("earners-levy__form-error-message");
    errorMessage[0].classList.remove("u-hidden");
    return;
  } else {
    calculateEarnersLevy(liableEarnings);
  }
}

export function dollarize(value) {
  let valueAsCurrency = new Intl.NumberFormat('en-NZ', {style: 'currency', currency: 'NZD'}).format(value);
  
  if (!value) return '$0';
  return valueAsCurrency;
}

export function calculateEarnersLevy(liableEarnings) {
  /*global earnersLevyObj*/

  //initialise variables
  let currentLiableEarnings;
  let proposedLiableEarningsYear1;
  let proposedLiableEarningsYear2;
  let proposedLiableEarningsYear3;

  let currentGST;
  let proposedGST;

  let currentEarnersLevyRate;

  let proposedEarnersLevyRateYear1;
  let proposedEarnersLevyRateYear2;
  let proposedEarnersLevyRateYear3;

  // Set liable earnings based on CMS max and min
  currentLiableEarnings = setLiableEarnings(liableEarnings, earnersLevyObj.currentMaxLiableEarnings, earnersLevyObj.currentMinLiableEarnings);
  proposedLiableEarningsYear1 = setLiableEarnings(liableEarnings, earnersLevyObj.proposedMaxLiableEarningsYear1, earnersLevyObj.proposedMinLiableEarningsYear1);
  proposedLiableEarningsYear2 = setLiableEarnings(liableEarnings, earnersLevyObj.proposedMaxLiableEarningsYear2, earnersLevyObj.proposedMinLiableEarningsYear2);
  proposedLiableEarningsYear3 = setLiableEarnings(liableEarnings, earnersLevyObj.proposedMaxLiableEarningsYear3, earnersLevyObj.proposedMinLiableEarningsYear3);
 
  currentGST = earnersLevyObj.currentGST;
  proposedGST = earnersLevyObj.proposedGST;
  
  currentEarnersLevyRate = earnersLevyObj.currentEarnersLevyRate;

  proposedEarnersLevyRateYear1= earnersLevyObj.proposedEarnersLevyRateYear1;
  proposedEarnersLevyRateYear2= earnersLevyObj.proposedEarnersLevyRateYear2;
  proposedEarnersLevyRateYear3= earnersLevyObj.proposedEarnersLevyRateYear3;

  //calculations
  let currentPayableEarnersLevy = currentEarnersLevyCalc(currentLiableEarnings, currentEarnersLevyRate, currentGST);
  
  let proposedPayableEarnersLevyYear1 = proposedEarnersLevyCalc(proposedLiableEarningsYear1, proposedEarnersLevyRateYear1, proposedGST);
  let proposedPayableEarnersLevyYear2 = proposedEarnersLevyCalc(proposedLiableEarningsYear2, proposedEarnersLevyRateYear2, proposedGST);
  let proposedPayableEarnersLevyYear3 = proposedEarnersLevyCalc(proposedLiableEarningsYear3, proposedEarnersLevyRateYear3, proposedGST);
  
  let earnersLevyDifferenceYear1 = differenceCalc(proposedPayableEarnersLevyYear1, currentPayableEarnersLevy);
  let earnersLevyDifferenceYear2 = differenceCalc(proposedPayableEarnersLevyYear2, currentPayableEarnersLevy);
  let earnersLevyDifferenceYear3 = differenceCalc(proposedPayableEarnersLevyYear3, currentPayableEarnersLevy);

  let differenceTextYear1 = differenceText(earnersLevyDifferenceYear1);
  let differenceTextYear2 = differenceText(earnersLevyDifferenceYear2);
  let differenceTextYear3 = differenceText(earnersLevyDifferenceYear3);

  //Append difference text to dom
  document.getElementById("el-calculated-difference-text-year-1").innerHTML = differenceTextYear1;
  document.getElementById("el-calculated-difference-text-year-2").innerHTML = differenceTextYear2;
  document.getElementById("el-calculated-difference-text-year-3").innerHTML = differenceTextYear3;

  //Append dollarized calculations to DOM
  document.getElementById("earners-levy-current").innerHTML = dollarize(currentPayableEarnersLevy);
  document.getElementById("earners-levy-proposed-year-1").innerHTML = dollarize(proposedPayableEarnersLevyYear1);
  document.getElementById("earners-levy-proposed-year-2").innerHTML = dollarize(proposedPayableEarnersLevyYear2);
  document.getElementById("earners-levy-proposed-year-3").innerHTML = dollarize(proposedPayableEarnersLevyYear3);
  
  document.getElementById("earners-levy-difference-year-1").innerHTML = dollarize(earnersLevyDifferenceYear1);
  document.getElementById("earners-levy-difference-year-2").innerHTML = dollarize(earnersLevyDifferenceYear2);
  document.getElementById("earners-levy-difference-year-3").innerHTML = dollarize(earnersLevyDifferenceYear3);

  //Hide income input div and show results div
  let incomeElement = document.getElementsByClassName("earners-levy__income-wrapper");
  incomeElement[0].classList.add("u-hidden");

  let resultElement = document.getElementsByClassName("earners-levy__calculated-wrapper");
  resultElement[0].classList.remove("u-hidden");

  window.sya.updateStickyElements();

  // Fire off GA event
  gtag('event', 'Submit - estimate', {
    event_category: "Tool - levy estimator",
    event_label: window.location.pathname
  });
}

export function currentEarnersLevyCalc(currentLiableEarnings, currentEarnersLevyRate, currentGST) {
    let raw = currentLiableEarnings * currentEarnersLevyRate;

    let result = raw + (raw * currentGST);

    return result;
}

export function proposedEarnersLevyCalc(proposedLiableEarnings, proposedRate, proposedGST) {
    
    let raw = proposedLiableEarnings * proposedRate;

    let result = raw + (raw * proposedGST);
    
    return result;
}

export function differenceCalc(proposedPayableEarnersLevy, currentPayableEarnersLevy) {
    let result = proposedPayableEarnersLevy - currentPayableEarnersLevy;
    return result;
};

export function differenceText(difference) {
  let decrease = "Decrease";
  let increase = "Increase";
  let noDifference = "No difference";

  return difference == 0 ? noDifference 
        : difference < 0 ? decrease 
        : increase;
}

export function startOverCalculationEarnersLevy() {

  document.getElementById("earners-levy-current").innerHTML = "$0.00";

  document.getElementById("earners-levy-proposed-year-1").innerHTML = "$0.00";
  document.getElementById("earners-levy-proposed-year-2").innerHTML = "$0.00";
  document.getElementById("earners-levy-proposed-year-3").innerHTML = "$0.00";
  
  document.getElementById("earners-levy-difference-year-1").innerHTML = "$0.00";
  document.getElementById("earners-levy-difference-year-2").innerHTML = "$0.00";
  document.getElementById("earners-levy-difference-year-3").innerHTML = "$0.00";

  document.getElementById("liable-earnings-input").value = "";

  //Hide results div and show income inout div
  let resultElement = document.getElementsByClassName("earners-levy__calculated-wrapper");
  resultElement[0].classList.add("u-hidden");

  let incomeElement = document.getElementsByClassName("earners-levy__income-wrapper");
  incomeElement[0].classList.remove("u-hidden");

  //remove error message
  let errorMessage = document.getElementsByClassName("earners-levy__form-error-message");
  errorMessage[0].classList.add("u-hidden");
}

export function initialiseELClickAndKeypressEnter() {
    let input = document.getElementById("liable-earnings-input");
    let calculateBtn = document.querySelector('#liable-earnings-submit-button')

    if (calculateBtn) {
      calculateBtn.addEventListener("click", function (event) {
        event.preventDefault();
        event.stopPropagation();

        calculateBtn.click(validateNumber());
      });
    };

    if (input) {
        input.addEventListener("keyup", function (event) {
            // Cancel the default action, may be a bug, sometimes doesn't prevent default
            event.preventDefault();
            event.stopPropagation();

            if (event.key === 'Enter') {
                // Trigger the button element with a click
                document.getElementById("liable-earnings-submit-button").click(validateNumber());
            }
        });
    }
}
