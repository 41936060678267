import ClipboardJS from 'clipboard';

export default {
  copyUrl: function() {
    const clipboard = new ClipboardJS('#copyUrlId', {
      text: function() {
        return window.location.href;
      }
    });
  },
  
  toggleShare: function(){
    if(document.getElementsByClassName('share--open').length > 0){
      document.getElementsByClassName('share')[0].classList.remove('share--open');
    } else{
      document.getElementsByClassName('share')[0].classList.add('share--open');
    }
  }
}